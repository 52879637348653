<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      :is-full-screen="false"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>

    <v-layout wrap v-else>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <span>PROMO CODES</span>
            <v-spacer></v-spacer>
            <v-flex xs12 sm2 pl-3>
              <v-select
                v-model="type"
                :items="typeArray"
                dense
                outlined
                clearable
                color="#B1B1B1"
                hide-details=""
                placeholder="Type"
                label="Type"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm2 pl-3>
              <v-select
                v-model="filter"
                :items="status"
                dense
                outlined
                clearable
                color="#B1B1B1"
                hide-details=""
                placeholder="Status"
                label="Status"
              ></v-select>
            </v-flex>
            <v-flex xl1 lg1 md1 sm1 xs3 px-5>
              <v-btn outlined color="green" @click="dialog = true"> Add </v-btn>
            </v-flex>
          </v-card-title>
        </v-card>
        <div>
          <v-container grid-list-md v-if="coupon.length > 0">
            <v-layout wrap pb-10>
              <v-flex v-for="(item, i) in coupon" :key="i" md6 lg6 pa-4>
                <v-card elevation="5" outlined>
                  <v-layout wrap justify-center px-2 py-5>
                    <v-flex xs12>
                      <v-btn color="red" block
                        ><span style="color: white">{{
                          item.code
                        }}</span></v-btn
                      >
                    </v-flex>
                    <v-layout wrap>
                      <v-flex xs12 sm6 text-left pa-2>
                        <span style="font-size: 14px"
                          >Change Promo code status</span
                        >
                      </v-flex>
                      <v-flex xs12 sm6 text-right pa-2>
                        <v-btn
                          color="green"
                          small
                          depressed
                          @click="statusChange(item)"
                          ><span style="color: white">{{
                            item.status
                          }}</span></v-btn
                        >
                      </v-flex>
                    </v-layout>
                    <v-flex xs12>
                      <span style="font-size: 14px">Offer Validity</span>
                      <br />
                      <v-flex
                        v-if="
                          currDate.getTime() > new Date(item.ending).getTime()
                        "
                      >
                        <span style="font-weight: bold; color: red"
                          >Expired</span
                        >
                        <br />
                        <span style="font-weight: bold"
                          >{{ item.endingDate }}
                          {{ formatTime(item.endingTime) }}</span
                        >
                      </v-flex>

                      <v-flex v-else>
                        <span style="font-weight: bold"
                          >{{ item.endingDate }}
                          {{ formatTime(item.endingTime) }}</span
                        >
                      </v-flex>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        outlined
                        color="red"
                        :to="'/promocodeReport?id=' + item._id"
                      >
                        View Report
                      </v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xl12 md12 sm12 xs12>
                      <span style="font-size: 16px; color: #000000">
                        <b>{{ item.description }}</b>
                      </span></v-flex
                    >
                    <v-layout wrap pa-2>
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Promo Type :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.promoType }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm2 text-left v-if="item.productId">
                        <span style="font-size: 14px; color: #000000">
                          Product :
                        </span></v-flex
                      >
                      <v-flex xs12 sm10 text-right v-if="item.productId">
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.productId.productname }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm2 text-left v-if="item.sellerId">
                        <span style="font-size: 14px; color: #000000">
                          Seller :
                        </span></v-flex
                      >
                      <v-flex xs12 sm10 text-right v-if="item.sellerId">
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.sellerId.organization }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm3 text-left v-if="item.stylCategoryId">
                        <span style="font-size: 14px; color: #000000">
                          StyCategory :
                        </span></v-flex
                      >
                      <v-flex xs12 sm9 text-right v-if="item.stylCategoryId">
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.stylCategoryId.name }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Start Date and Start Time :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b
                            >{{ item.startingDate }}
                            {{ formatTime(item.startingTime) }}
                          </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          End Date and End Time :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b
                            >{{ item.endingDate }}
                            {{ formatTime(item.endingTime) }}
                          </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Discount :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.discount }} %</b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Maximum Discount Amount :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.maxDiscountAmount }} </b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Maximum Oder To Be Applied :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.maximumOderTobeApplied }}</b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Minimum Order Amount :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.orderMinAmount }}</b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Limit Per Customer :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right>
                        <span style="font-size: 14px; color: #000000">
                          <b>{{ item.limitPerCustomer }}</b>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Is For First Order :
                        </span></v-flex
                      >
                      <v-flex
                        xs12
                        sm6
                        text-right
                        v-if="item.isForFirstOrder == true"
                      >
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="green">mdi-check-circle</v-icon>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-else>
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="red">mdi-close-circle</v-icon>
                        </span></v-flex
                      >

                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Is Styloop :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-if="item.styloop == true">
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="green">mdi-check-circle</v-icon>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-else>
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="red">mdi-close-circle</v-icon>
                        </span></v-flex
                      >

                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Is Stylbay :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-if="item.stylbae == true">
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="green">mdi-check-circle</v-icon>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-else>
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="red">mdi-close-circle</v-icon>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-left>
                        <span style="font-size: 14px; color: #000000">
                          Platform :
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-if="item.platform == true">
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="green">mdi-check-circle</v-icon>
                        </span></v-flex
                      >
                      <v-flex xs12 sm6 text-right v-else>
                        <span style="font-size: 14px; color: #000000">
                          <v-icon color="red">mdi-close-circle</v-icon>
                        </span></v-flex
                      >
                    </v-layout>

                    <v-flex pt-5 px-4 xs6 sm6 md6 lg6 xl6 justify-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Delete
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this Promo
                            Code?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex pt-5 px-4 pb-2 xs6 sm6 md6 lg6 xl6 justify-center>
                      <v-btn
                        small
                        block
                        class="mr-2"
                        outlined
                        color="green"
                        @click="editSlider(item)"
                      >
                        Edit
                      </v-btn>
                    </v-flex>
                    <v-flex
                      pt-5
                      px-4
                      xs6
                      sm6
                      md6
                      lg6
                      xl6
                      justify-center
                      v-if="item.status == 'Pending'"
                    >
                      <v-dialog v-model="item.approve" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="green"
                          >
                            Approve
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to approve this Promo
                            Code?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.approve = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="approve(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex
                      pt-5
                      px-4
                      xs6
                      sm6
                      md6
                      lg6
                      xl6
                      justify-center
                      v-if="item.status == 'Pending'"
                    >
                      <v-dialog v-model="item.reject" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Reject
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to reject this Promo
                            Code?</v-card-title
                          >
                          <v-form v-model="remove" ref="remove">
                            <v-col cols="12">
                              <v-text-field
                                outlined
                                :rules="Rules"
                                v-model="reason"
                                label="Reason"
                              >
                              </v-text-field>
                            </v-col>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.reject = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                :disabled="!remove"
                                text
                                @click="reject(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <br />
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container grid-list-md v-else>
            <v-layout wrap justify-center py-10>
              <v-flex xs12>
                <span>No Promocode Found....</span>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <v-card>
            <v-form v-model="addslider">
              <v-card-title v-if="edit == false">
                <span class="headline">Add Promo Code</span>
              </v-card-title>
              <v-card-title v-else>
                <span class="headline">Edit Promo Code</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="couponData.code"
                        label="Promo Code"
                        :rules="Rules"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="couponData.description"
                        label="Description"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="couponData.discount"
                        label="Discount Percentage"
                        :rules="Rules"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="couponData.maxAmount"
                        label="Maximum Amount To Be Applied"
                        :rules="Rules"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="couponData.startingDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="couponData.startingDate"
                            label="Select From Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="couponData.startingDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(couponData.startingDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="couponData.endingDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="couponData.endingDate"
                            label="Select To Date"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="couponData.endingDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(couponData.endingDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="couponData.startingTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="couponData.startingTime"
                            label="Starting Time"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="couponData.startingTime"
                          full-width
                          @click:minute="
                            $refs.menu3.save(couponData.startingTime)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="couponData.endingTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="couponData.endingTime"
                            label="Ending Time"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu4"
                          v-model="couponData.endingTime"
                          full-width
                          @click:minute="
                            $refs.menu4.save(couponData.endingTime)
                          "
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="edit == false">
                      <span>Select Type</span>
                      <v-radio-group v-model="radios" mandatory>
                        <v-radio label="Platform" value="Platform"></v-radio>
                        <v-radio
                          label="StylCategory"
                          value="stylCategoryId"
                        ></v-radio>
                        <v-radio label="Seller" value="sellerId"></v-radio>
                        <v-radio label="Product" value="productId"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="edit == true">
                      <span>Select Type</span>
                      <v-radio-group v-model="radios" mandatory disabled>
                        <v-radio label="Platform" value="Platform"></v-radio>
                        <v-radio
                          label="StylCategory"
                          value="stylCategoryId"
                        ></v-radio>
                        <v-radio label="Seller" value="sellerId"></v-radio>
                        <v-radio label="Product" value="productId"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="radios == 'stylCategoryId'"
                    >
                      <v-autocomplete
                        v-model="couponData.stylCategoryId"
                        :items="stylArray"
                        item-text="name"
                        item-value="_id"
                        item-color="#8d8d8d"
                        color="#8d8d8d"
                        outlined
                        dense
                        label="Select StylCategory"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="radios == 'sellerId'"
                    >
                      <v-autocomplete
                        v-model="couponData.sellerId"
                        :items="sellerArray"
                        item-text="organization"
                        item-value="_id"
                        item-color="#8d8d8d"
                        color="#8d8d8d"
                        outlined
                        dense
                        label="Select Seller"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="radios == 'productId'"
                    >
                      <v-autocomplete
                        v-model="couponData.productId"
                        :items="productArray"
                        item-text="productname"
                        item-value="_id"
                        item-color="#8d8d8d"
                        color="#8d8d8d"
                        outlined
                        dense
                        label="Select Product"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="couponData.maximumOderTobeApplied"
                        label="Maximum Order To Be Applied "
                        :rules="Rules"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="couponData.orderMinAmount"
                        label="Minimum Order Amount"
                        :rules="Rules"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="couponData.limitPerCustomer"
                        label="Limit Per Customer "
                        :rules="Rules"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" v-if="radios == 'Platform'">
                      <v-switch
                        v-model="couponData.isForFirstOrder"
                        label="Is First Order"
                        outlined
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-switch
                        v-model="couponData.isForStyloop"
                        label="Is For Styloop"
                        outlined
                      ></v-switch>
                    </v-col>

                    <v-col cols="12" sm="12" md="4">
                      <v-switch
                        v-model="couponData.isForStylBay"
                        label="Is For StylBay"
                        outlined
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="(dialog = false), (couponData = ''), (radios = '')"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="edit == false"
                  color="blue darken-1"
                  text
                  :disabled="!addslider"
                  @click="add()"
                >
                  Add
                </v-btn>
                <v-btn
                  v-else
                  color="blue darken-1"
                  text
                  :disabled="!addslider"
                  @click="editItem()"
                >
                  Edit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      code: null,
      widthOfCard: "250px",
      addslider: false,
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      editdialog: false,
      imgId: "",
      editingitem: "",
      menu1: false,
      menu: false,
      menu3: false,
      menu4: false,
      coupon: [],
      radios: null,
      edit: false,
      productArray: [],
      sellerArray: [],
      stylArray: [],
      currDate: new Date(),
      couponData: {
        discount: null,
        code: null,
        description: "",
        startingDate: new Date().toISOString().substr(0, 10),
        endingDate: new Date().toISOString().substr(0, 10),
        startingTime: "",
        endingTime: "",
        maxAmount: "",
        isForStyloop: true,
        isForFirstOrder: true,
        isForStylBay: true,
        maximumOderTobeApplied: "",
        orderMinAmount: 0,
        sellerId: "",
        productId: "",
        stylCategoryId: "",
        limitPerCustomer: "",
      },
      type: "",
      typeArray: [
        {
          value: "stylCategory",
          text: "StylCategory",
        },
        {
          value: "seller",
          text: "Seller",
        },
        {
          value: "product",
          text: "Product",
        },
        {
          value: "platform",
          text: "Platform",
        },
      ],
      filter: "Active",
      status: [
        { value: "Active", text: "Active" },
        { value: "Pending", text: "Pending" },
        { value: "Inactive", text: "Inactive" },
      ],
      editId: "",
      count: 12,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      Rules: [(value) => !!value || "Required."],
      reason: null,
      remove: false,
    };
  },
  watch: {
    type() {
      this.getData();
    },
    radios() {
      if (this.radios == "stylCategoryId") {
        this.couponData.sellerId = null;
        this.couponData.productId = null;
      }
      if (this.radios == "sellerId") {
        this.couponData.stylCategoryId = null;
        this.couponData.productId = null;
      }
      if (this.radios == "productId") {
        this.couponData.stylCategoryId = null;
        this.couponData.sellerId = null;
      }
    },
    filter() {
      this.getData();
    },
  },
  beforeMount() {
    this.getData();
    this.getStyle();
    this.getSeller();
    this.getProduct();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "sliderImage") {
        this.sliderImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/PromoCode/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.count,
          page: this.currentPage,
          filter: this.filter,
          type: this.type,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.coupon = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getStyle() {
      this.appLoading = true;
      axios({
        url: "/styl/getlistAll",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stylArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getSeller() {
      this.appLoading = true;
      axios({
        url: "/seller/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.sellerArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getProduct() {
      this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.productArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    approve(item) {
      axios({
        method: "POST",
        url: "/admin/Approve/Reject/PromoCode",
        data: {
          id: item._id,
          status: "Aproved",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.approveDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Approved";
            this.showsnackbar = true;
            this.getData();
            this.approve = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    reject(item) {
      axios({
        method: "POST",
        url: "/admin/Approve/Reject/PromoCode",
        data: {
          id: item._id,
          status: "Rejected",
          reason: this.reason,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.approveDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Rejected";
            this.showsnackbar = true;
            this.getData();
            this.reject = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        url: "/admin/Add/PromoCode",
        method: "POST",
        data: this.couponData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.couponData = "";
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    statusChange(id) {
      axios({
        url: "/admin/PromoCode/ChangeStatus",
        method: "POST",
        data: {
          id: id._id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Status Changed Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    deleteItem(id) {
      var data = {};
      data["id"] = id._id;
      axios({
        url: "/admin/Delete/PromoCode",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialogDelete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    editSlider(item) {
      this.edit = true;
      this.couponData = item;
      if (this.couponData.sellerId) {
        if (this.couponData.sellerId.organization) {
          this.radios = "sellerId";
        }
      }
      if (this.couponData.productId) {
        if (this.couponData.productId.productname) {
          this.radios = "productId";
        }
      }
      if (this.couponData.stylCategoryId) {
        if (this.couponData.stylCategoryId.name) {
          this.radios = "stylCategoryId";
        }
      }
      this.dialog = true;
      this.couponData.maxAmount = this.couponData.maxDiscountAmount;
      this.couponData.isForStyloop = this.couponData.styloop;
      this.couponData.isForStylBay = this.couponData.stylbae;
      this.couponData.startingDate = this.couponData.startingDate.slice(0, 10);
      this.couponData.endingDate = this.couponData.endingDate.slice(0, 10);
      this.editId = item._id;
    },
    editItem() {
      this.appLoading = true;
      this.couponData.id = this.editId;
      axios({
        method: "POST",
        url: "/admin/Edit/PromoCode",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.couponData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Edited Sucessfully";
            this.couponData = "";
            this.showsnackbar = true;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
